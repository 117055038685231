import * as React from 'react';
import {
  Flex,
  Box,
  Heading,
  useColorModeValue,
  Badge,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  TableContainer,
  Table,
  Tr,
  Tbody,
  Td,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { GET_RESULTS } from '../api/queries';
import Loading from '../components/Loading';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { clearToken } from '../redux/actions';
import { SUBSCRIBE_RESULTS } from '../api/subscriptions';

const parseResults = (results) => {
  if (!results) {
    return null;
  }

  // Sort
  results = [...results].sort((a, b) =>
    a.votes < b.votes ? 1 : a.votes > b.votes ? -1 : 0,
  );

  // Compute relative
  const totalVotes = results.reduce((accum, curr) => accum + curr.votes, 0);
  results = results.map((result) => ({
    ...result,
    relative:
      totalVotes > 0 ? Math.round((result.votes / totalVotes) * 100) : 0,
  }));

  // Compute positions
  results[0].pos = 1;
  for (let i = 1; i < results.length; i++) {
    const last = results[i - 1];
    const curr = results[i];
    results[i].pos = curr.votes === last.votes ? last.pos : i + 1;
  }

  return results;
};

const resultPropsByPos = (pos) => {
  switch (pos) {
    case 1: {
      return {
        badgeProps: {
          colorScheme: 'yellow',
          fontSize: '1.2em',
        },
        badgeText: '1st',
        headingProps: {
          size: '2xl',
        },
        circleProps: {
          size: '2.5em',
          color: 'yellow.400',
        },
        percentageProps: {
          color: 'yellow.700',
          fontSize: '0.6em',
        },
      };
    }
    case 2: {
      return {
        badgeProps: {
          colorScheme: 'gray',
          fontSize: '1em',
        },
        badgeText: '2nd',
        headingProps: {
          size: 'xl',
        },
        circleProps: {
          size: '2.3em',
          color: 'gray.400',
        },
        percentageProps: {
          color: 'gray.700',
          fontSize: '0.5em',
        },
      };
    }
    case 3: {
      return {
        badgeProps: {
          colorScheme: 'orange',
          fontSize: '0.8em',
        },
        badgeText: '3rd',
        headingProps: {
          size: 'lg',
        },
        circleProps: {
          size: '2.1em',
          color: 'orange.400',
        },
        percentageProps: {
          color: 'orange.700',
          fontSize: '0.4em',
        },
      };
    }
    default:
      return {};
  }
};

const BuildResultRowHead = ({ id, name, relative, pos }) => {
  const { badgeProps, badgeText, headingProps, circleProps, percentageProps } =
    resultPropsByPos(pos);

  return (
    <Tr>
      <Td textAlign="center">
        <Badge {...badgeProps} py={2} px={4} rounded={'lg'}>
          {badgeText}
        </Badge>
      </Td>
      <Td>
        <Heading {...headingProps}>{name}</Heading>
      </Td>
      <Td textAlign="center">
        <CircularProgress thickness={'0.4em'} {...circleProps} value={relative}>
          <CircularProgressLabel {...percentageProps} fontWeight={'bold'}>
            {relative}%
          </CircularProgressLabel>
        </CircularProgress>
      </Td>
    </Tr>
  );
};

const BuildResultRowTail = ({ id, name, relative, pos }) => {
  return (
    <Tr>
      <Td textAlign="center">{pos}th</Td>
      <Td>{name}</Td>
      <Td textAlign="center">{relative}%</Td>
    </Tr>
  );
};

const BuildResultRow = (props) => {
  return props.pos <= 3 ? (
    <BuildResultRowHead key={props.id} {...props} />
  ) : (
    <BuildResultRowTail key={props.id} {...props} />
  );
};

const ResultsTable = ({ results, subscribeToResults }) => {
  React.useEffect(() => subscribeToResults(), [subscribeToResults]);
  results = parseResults(results);

  return (
    <Box
      rounded={'lg'}
      bg={useColorModeValue('white', 'gray.700')}
      boxShadow={'lg'}
      w={'100%'}
      p={4}
    >
      <TableContainer w={'100%'}>
        <Table variant="simple">
          <Tbody>{results?.map(BuildResultRow)}</Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const onError = React.useCallback(() => dispatch(clearToken()), [dispatch]);
  const { subscribeToMore, loading, data } = useQuery(GET_RESULTS, {
    onError,
  });
  const subscribeToResults = React.useCallback(
    () =>
      subscribeToMore({
        document: SUBSCRIBE_RESULTS,
        variables: {},
        updateQuery: (prev, { subscriptionData }) => {
          const resultChanged = subscriptionData?.data?.resultChanged;
          if (!resultChanged) return prev;

          const { id, votes } = resultChanged;
          return {
            results: prev?.results?.map((result) => {
              return result.id === id ? { ...result, votes } : result;
            }),
          };
        },
      }),
    [subscribeToMore],
  );

  return (
    <Flex
      minH={'100vh'}
      align={'start'}
      justify={'center'}
      px={10}
      bg={'#6eb6fe'}
    >
      <VStack marginTop={4} spacing={6} w={'full'}>
        <HStack w={'full'} spacing={'auto'}>
          <Image width={'8em'} alt="BDE Logo" src="/bde.png" />
          <VStack>
            <Image width={'20em'} alt="Title" src="/title.jpg" />
            <Text
              fontFamily={'monospace'}
              fontWeight={'bold'}
              fontSize={'1.8em'}
              color={'white'}
            >
              Come to the voting stand to choose your favorite region, we are
              waiting for you!
            </Text>
          </VStack>
          <Image width={'6em'} alt="Télécom Logo" src="/telecom.png" />
        </HStack>
        {loading ? (
          <Loading />
        ) : (
          <ResultsTable
            subscribeToResults={subscribeToResults}
            results={data?.results}
          />
        )}
      </VStack>
    </Flex>
  );
};

export default Home;
